import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BasketItemsService } from '../services/basket-items.service';
import { DialogsService } from '../services/dialogs.service';
import { FavoritesService } from '../services/favorites.service';
import { FirmService } from '../services/firm.service';
import { RouteService } from '../services/route.service';
import { CustomerService } from '../services/customer.service';
import { OrderService } from '../services/order.service';
import { Units } from '../constants/units';
import { ProductService } from '../services/products.service';
import { LanguageService } from '../services/language.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent {
  @Input() firm: any;
  @Input() own_domain: boolean;
  @Input() language: boolean;

  login: any;
  user: any;
  search_term: any;
  basketItems: any;
  sub_total: any;
  showMenu = false;
  selectedLang: any;
  categories: any = [];
  units = Units;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public customerService: CustomerService,
    public firmService: FirmService,
    public basket: BasketItemsService,
    public translate: TranslateService,
    public dialogsService: DialogsService,
    public favoritesService: FavoritesService,
    public routeService: RouteService,
    public orderService: OrderService,
    private productService: ProductService,
    private languageService: LanguageService
  ) {}

  ngOnInit() {
    // nasty fix for basket items not loading
    setTimeout(async () => {
      if (!this.basket.basketAmount && this.firm) {
        this.basketItems = await this.basket.get(this.firm.id);
        this.basket.basketAmount = this.basket.getAmount(this.firm.id);
      }
    }, 5000);

    this.languageService.setLanguage(this.language);
  }

  async ngOnChanges() {
    await this.customerService.setLocalCustomer();
    const response = localStorage.getItem('login');

    if (response === null || response === undefined || response === 'undefined') {
      this.login == response;
    } else {
      this.login = JSON.parse(response);
    }

    if (this.login && this.firm) {
      await this.customerService.linkFirm(
        this.firm,
        this.login.id,
        this.language,
        this.firm.distributor,
        this.own_domain
      );
      this.user = await this.customerService.get(
        this.firm,
        this.login.id,
        this.language,
        this.firm.distributor,
        this.own_domain
      );
    }

    if (this.firm) {
      this.basketItems = await this.basket.get(this.firm.id);
      this.basket.basketAmount = this.basket.getAmount(this.firm.id);
      if (!this.firm.uses_vat) {
        this.basket.basketPrice = this.basket.calculatePrice(this.firm.id);
      } else {
        this.basket.basketPrice = this.basket.calculateVatPrice(this.firm.id);
      }
      this.checkDeliverect();
    }
  }

  async checkDeliverect() {
    if (this.firm && this.firm.uses_deliverect) {
      this.categories = await this.productService.getCategoriesByFirmId(this.firm.id);

      if (!this.categories || this.categories.length === 0) {
        await this.productService.getCategories(this.firm, this.language, this.firm.uses_deliverect);
        this.checkDeliverect();
      }
    }
  }

  async changeLanguage(lang) {
    if (this.basket.basketAmount > 0) {
      this.basket.removeAll(this.firm.id);
    }

    if (!this.own_domain) {
      await this.router.navigate([this.firm.website_details.sitename, lang.code.toLowerCase()]);
      window.location.reload();
    } else {
      if (this.firm.parent_is_multifirm) {
        await this.router.navigate([this.firm.website_details.sitename, lang.code.toLowerCase()]);
        window.location.reload();
      } else {
        await this.router.navigate([lang.code.toLowerCase()]);
        window.location.reload();
      }
    }
  }

  logout() {
    this.customerService.logout();
    this.login = null;

    this.dialogsService
      .openError(this.translate.instant('DIALOGS.LOGOUT_TITLE'), this.translate.instant('DIALOGS.LOGOUT_MESSAGE'), true)
      .subscribe(() => {
        if (!this.own_domain) {
          this.router.navigate([this.firm.website_details.sitename, this.language]);
        } else {
          if (this.firm.parent_is_multifirm) {
            this.router.navigate([this.firm.website_details.sitename, this.language]);
          } else {
            this.router.navigate([this.language]);
          }
        }
      });
  }

  isReservationEnabled(): boolean {
    return this.firm && this.firmService.isReservationEnabled(this.firm.reservations);
  }

  signin() {
    this.routeService.redirectTo = 'checkout';
    this.routeService.navigateToProfile(this.own_domain, this.firm, this.language);
  }
}
