import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../services/auth.service';
import { BasketItemsService } from '../services/basket-items.service';
import { DialogsService } from '../services/dialogs.service';
import { FirmService } from '../services/firm.service';
import { LoaderService } from '../services/loader.service';
import { OrderService } from '../services/order.service';
import { PaymentService } from '../services/payment.service';
import { ProductService } from '../services/products.service';
import { RouteService } from '../services/route.service';
import { SlugifyService } from '../services/slugify.service';
import { Units } from '../constants/units';
import { ServiceFee } from '../constants/service_fee';
import { LanguageService } from '../services/language.service';
import { LoyaltyService } from '../services/loyalty.service';
import { TagService } from '../services/tag.service';
import { CustomerService } from '../services/customer.service';
import { Address } from '../core/models/address';

/* Sanitize pipe for Google Maps url */
@Pipe({ name: 'minimumPrice' })
export class MinimumPricePipe implements PipeTransform {
  constructor() {}
  transform(value) {
    if (value < 0) {
      value = 0;
    }
    return value;
  }
}

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent implements OnInit {
  units = Units;
  serviceFee = ServiceFee;
  user: any;
  userId: string;
  own_domain: boolean;
  order: any;
  firm: any;
  firm_name: any;
  language: any;
  basketItems: any;
  sub_total: any;
  total_price: any;
  delivery_cost: any;
  errMessage: any;
  delivery_method: any;
  datetime: any;
  pickup_point: any;
  enableOrderBtn = true;
  VAT_percentage: any;
  VAT: any;
  VAT_total: any;
  coupon: any = undefined;
  enterCoupon = true;
  couponInvalid = false;
  couponAlreadyUsed = false;
  couponTooLow = false;
  couponTooLowMsg: any;
  couponError: string;
  service_fee_price: number;
  comment: string;
  selected_coupon_product: any;
  selected_loyalty_product: any;
  loyaltyProducts: any = null;
  extraOptions: any;
  selectedInvoiceAddress: Address = null;
  addressList: Address[];
  paymentCallCounter = 0;

  /* Basket change */
  enable_change_basket = false;
  errors: any;
  invalid_product = false;
  invalid_ingredient = false;
  invalid_option = false;
  not_in_stock = false;
  product_not_available = false;
  ingredient_not_available = false;
  category_not_available = false;
  shop_offline = false;
  offline_settings: any;
  isFree = false;
  generalError = false;

  constructor(
    public auth: AuthService,
    public route: ActivatedRoute,
    public router: Router,
    public routeService: RouteService,
    public translate: TranslateService,
    public slug: SlugifyService,
    public orderService: OrderService,
    public firmService: FirmService,
    public basketItemsService: BasketItemsService,
    public paymentService: PaymentService,
    public dialogsService: DialogsService,
    public loader: LoaderService,
    public productService: ProductService,
    private languageService: LanguageService,
    private loyaltyService: LoyaltyService,
    private tagService: TagService,
    private customerService: CustomerService
  ) {
    window.scrollTo(0, 0);

    // Route params
    this.firm_name = route.snapshot.params.firm_name;
    this.language = route.snapshot.params.language;
    this.languageService.setLanguage(this.language);
  }

  /* Get order */
  async ngOnInit() {
    this.firmService.getFirmByCheckingDomain(this.firm_name, this.language).then(async (data: any) => {
      this.firm = data.firm;
      this.own_domain = data.own_domain;

      // Get order
      this.order = await this.orderService.getOrder();

      this.checkForErrors();
    });
  }

  checkForErrors() {
    if (!this.firm.has_website) {
      this.router.navigate([this.firm.website_details.sitename, this.language, '404']);
    } else if (!this.order) {
      this.routeService.navigateToBasket(this.own_domain, this.firm, this.language);
    } else if (!this.order.process_timestamp && !this.order.user && !this.order.payment_method_id) {
      this.routeService.navigateToBasket(this.own_domain, this.firm, this.language);
    } else {
      this.initData();
    }
  }

  async initData() {
    if (this.order.coupon) {
      this.enterCoupon = false;
      this.couponInvalid = false;
      this.couponAlreadyUsed = false;

      if (this.order.coupon.type === 'category') {
        this.selected_coupon_product = this.order.coupon.free_products.find(
          (prod) => prod.id === this.order.coupon_product_id
        );
      }
    }

    if (!this.order.isGuest) {
      this.userId = await this.getUserIdFromStorage();
      this.user = await this.getUser();
    }

    this.basketItems = await this.basketItemsService.get(this.firm.id);

    // Check for params
    await this.checkForParams();

    if (this.isLoyaltyActive()) this.setLoyaltyProducts();

    // Check pricelist discount
    if (!this.firm.pricelists) {
      this.order.discount_percentage = 0;
    }

    // Get pickup point
    if (this.order.firm_pickup_point_id) {
      this.firm.pickup_points.forEach((pickup_point) => {
        if (pickup_point.id == this.order.firm_pickup_point_id) {
          this.pickup_point = pickup_point;
        }
      });
    }

    // Add checkout options
    if (this.firm.checkout_options && this.firm.checkout_options.length > 0) {
      this.firm.checkout_options.forEach((option) => {
        if (option.selected) {
          this.addExtraToOrder(option);
        }
      });
      this.forceExtrasUpdate();
    }

    // Set locale and process_timestamp
    moment.locale(this.language);
    this.datetime = moment(this.order.process_timestamp).format('LL HH:mm');
  }

  async getUserIdFromStorage() {
    const login: any = await JSON.parse(localStorage.getItem('login'));
    return login?.id;
  }

  getUser() {
    return this.customerService.get(this.firm, this.userId, this.language, this.firm.distributor, this.own_domain);
  }

  async checkForParams() {
    let params;
    const payment_activated = await JSON.parse(sessionStorage.getItem('payment_activated'));
    sessionStorage.removeItem('payment_activated');

    this.route.queryParams.subscribe((route_params) => {
      params = route_params;
    });

    if (params.accept || params.cancel || params.decline || params.exception) {
      this.checkParams(params);
    } else if (payment_activated) {
      this.deleteOrder();
    } else {
      this.checkOrder();
    }
  }

  setPricesForVat() {
    this.sub_total = 0;
    this.basketItems.forEach((basketItem) => {
      const item_price =
        basketItem.item.total_price -
        (basketItem.item.total_price / 100) * this.order.discount_percentage +
        ((basketItem.item.total_price - (basketItem.item.total_price / 100) * this.order.discount_percentage) / 100) *
          basketItem.item.item.vat_percentage;
      this.sub_total = Number(this.sub_total + item_price);
    });
    this.calculateServiceFees();
  }

  calculateServiceFees() {
    if (this.firm.service_fee.length > 0) {
      this.service_fee_price = 0;

      this.firm.service_fee.forEach((service_fee) => {
        if (
          service_fee.active &&
          ((service_fee.online_only && this.order.payment_method_id && this.order.payment_method_id != 1) ||
            !service_fee.online_only) &&
          ((service_fee.free_from && this.sub_total < service_fee.free_from_amount) || !service_fee.free_from) &&
          (service_fee.firm_pickup_point_id === this.order.firm_pickup_point_id || !service_fee.firm_pickup_point_id)
        ) {
          if (service_fee.fee_choice === ServiceFee.FEE) {
            this.service_fee_price = this.service_fee_price + service_fee.fee;
          } else if (service_fee.fee_choice === ServiceFee.PERCENTAGE) {
            this.service_fee_price = this.service_fee_price + (this.sub_total / 100) * service_fee.percentage;
          }
        }
      });
    }
  }

  async checkOrder() {
    // check order for status
    if (this.order) {
      if (this.order.id && this.order.payment_method_id !== 1) {
        const payment_status = await this.getPaymentStatus();

        if (payment_status == true) {
          this.displaySucceededMessage();
        } else if (payment_status == false || payment_status == undefined) {
          this.deleteOrder();
        }
      } else if (this.order.id && this.order.payment_method_id == 1) {
        this.orderProcessedHandler(this.order.id);
      }
    }
  }

  /* Check the route params */
  async checkParams(params) {
    const payment_status = await this.getPaymentStatus();

    if (params.accept) {
      if (payment_status == true) {
        this.orderProcessedHandler(this.order.id);
      } else if (payment_status == false) {
        this.dialogsService
          .openError(
            this.translate.instant('DIALOGS.PAYMENT_FAILED_TITLE'),
            this.translate.instant('DIALOGS.PAYMENT_FAILED_MESSAGE'),
            true
          )
          .subscribe(() => {
            this.deleteOrder();
          });
      }
    } else if (params.decline) {
      if (payment_status == true) {
        this.displaySucceededMessage();
      } else if (payment_status == false) {
        this.dialogsService.openError(null, this.translate.instant('DIALOGS.PAYMENT_DECLINED'), false);
        this.deleteOrder();
      }
    } else if (params.cancel) {
      if (payment_status == true) {
        this.displaySucceededMessage();
      } else if (payment_status == false) {
        this.dialogsService.openError(null, this.translate.instant('DIALOGS.PAYMENT_CANCELLED'), false);
        this.deleteOrder();
      }
    } else if (params.exception) {
      if (payment_status == true) {
        this.displaySucceededMessage();
      } else if (payment_status == false) {
        this.dialogsService.openError(null, this.translate.instant('DIALOGS.PAYMENT_EXCEPTION'), false);
        this.deleteOrder();
      }
    }
  }

  async getPaymentStatus() {
    if (this.order.payment_method_id !== 1) {
      if (this.order.id) {
        const response: any = await this.orderService.getStatus(
          this.firm.id,
          this.order.id,
          this.order.user.id,
          this.language
        );
        if (response == 'ERROR') {
          this.dialogsService
            .openError(
              this.translate.instant('DIALOGS.GET_PAYMENTSTATUS_FAILED_TITLE'),
              this.translate.instant('DIALOGS.GET_PAYMENTSTATUS_FAILED_MESSAGE'),
              true
            )
            .subscribe(() => {
              // Delete order & basketItems
              localStorage.setItem('order', undefined);
              this.basketItemsService.removeAll(this.firm.id);

              // Send back to basket page
              if (!this.own_domain) {
                this.router.navigate([this.firm.website_details.sitename, this.language, 'basket']);
              } else {
                if (this.firm.parent_is_multifirm) {
                  this.router.navigate([this.firm.website_details.sitename, this.language, 'basket']);
                } else {
                  this.router.navigate([this.language, 'basket']);
                }
              }
            });
        } else {
          if (response.paid == undefined) {
            response.paid = false;
          }
          return response.paid;
        }
      }
    }
  }

  /* Set order and send to server */
  async placeOrder() {
    if (this.order.payment_method_id == 6 && this.total_price < 0.5 && this.total_price != 0) {
      this.dialogsService.openError(
        this.translate.instant('AMOUNT_TOO_SMALL_TITLE'),
        this.translate.instant('AMOUNT_TOO_SMALL_STRIPE_MSG'),
        false
      );
    } else if (this.order.payment_method_id == 7 && this.total_price < 1 && this.total_price != 0) {
      this.dialogsService.openError(
        this.translate.instant('AMOUNT_TOO_SMALL_TITLE'),
        this.translate.instant('AMOUNT_TOO_SMALL_EMS_MSG'),
        false
      );
    } else {
      this.enableOrderBtn = false;

      if (this.comment) {
        this.order.comment = this.comment;
      }

      this.loader.present();
      const response: any = await this.orderService.add(
        this.firm,
        this.order,
        this.order.user.id,
        this.language,
        this.own_domain,
        this.isFree
      );

      sessionStorage.setItem('payment_activated', JSON.stringify(true));

      if (response.id) {
        this.enableOrderBtn = false;
        this.order.id = response.id;
        this.orderService.saveOrder(this.order);
        this.loader.dismiss();

        this.goToPayment(response.id);
      } else if (response.type == 'basket_change') {
        window.scrollTo(0, 0);
        this.enableOrderBtn = true;
        this.checkBasketChange(response);
      } else if (response.type == 'shop_offline') {
        this.enableOrderBtn = true;
        this.shop_offline = true;
        this.offline_settings = response.errors;
        this.loader.dismiss();
      }
    }
  }

  /* Generate payment */
  async goToPayment(order_id) {
    if (this.isFree == false) {
      sessionStorage.setItem('payment_activated', JSON.stringify(true));

      if (this.order.payment_method_id == 1) {
        this.orderProcessedHandler(order_id);
      } else if (this.order.payment_method_id == 2) {
        const response: any = await this.paymentService.ogone(
          this.firm,
          this.firm_name,
          this.order.user.id,
          order_id,
          this.language,
          this.own_domain
        );

        if (response == 'ERROR') {
          this.deleteOrder();
        } else if (response == 'ALREADY_PAID') {
          this.dialogsService
            .openError(
              this.translate.instant('DIALOGS.ALREADY_PAID_TITLE'),
              this.translate.instant('DIALOGS.ALREADY_PAID_MESSAGE'),
              true
            )
            .subscribe(() => {
              this.orderProcessedHandler(order_id);
            });
        }
      } else if (this.order.payment_method_id == 3) {
        const response: any = await this.paymentService.ideal(
          this.firm,
          this.firm_name,
          this.order.user.id,
          order_id,
          this.language,
          this.own_domain
        );

        if (response == 'ERROR') {
          this.deleteOrder();
        } else if (response == 'ALREADY_PAID') {
          this.dialogsService
            .openError(
              this.translate.instant('DIALOGS.ALREADY_PAID_TITLE'),
              this.translate.instant('DIALOGS.ALREADY_PAID_MESSAGE'),
              true
            )
            .subscribe(() => {
              this.orderProcessedHandler(order_id);
            });
        }
      } else if (this.order.payment_method_id == 4) {
        const response: any = await this.paymentService.payone(
          this.firm,
          this.firm_name,
          this.order.user.id,
          order_id,
          this.language,
          this.own_domain
        );

        if (response == 'ERROR') {
          this.deleteOrder();
        } else if (response == 'ALREADY_PAID') {
          this.dialogsService
            .openError(
              this.translate.instant('DIALOGS.ALREADY_PAID_TITLE'),
              this.translate.instant('DIALOGS.ALREADY_PAID_MESSAGE'),
              true
            )
            .subscribe(() => {
              this.orderProcessedHandler(order_id);
            });
        }
      } else if (this.order.payment_method_id == 5) {
        const response: any = await this.paymentService.ems(
          this.firm,
          this.firm_name,
          this.order.user.id,
          order_id,
          this.language,
          this.own_domain
        );

        if (response == 'ERROR') {
          this.deleteOrder();
        } else if (response == 'ALREADY_PAID') {
          this.dialogsService
            .openError(
              this.translate.instant('DIALOGS.ALREADY_PAID_TITLE'),
              this.translate.instant('DIALOGS.ALREADY_PAID_MESSAGE'),
              true
            )
            .subscribe(() => {
              this.orderProcessedHandler(order_id);
            });
        }
      } else if (this.order.payment_method_id == 6) {
        const response: any = await this.paymentService.stripe(
          this.firm,
          this.firm_name,
          this.order.user.id,
          order_id,
          this.language,
          this.own_domain
        );

        if (response == 'ERROR') {
          this.deleteOrder();
        } else if (response == 'ALREADY_PAID') {
          this.dialogsService
            .openError(
              this.translate.instant('DIALOGS.ALREADY_PAID_TITLE'),
              this.translate.instant('DIALOGS.ALREADY_PAID_MESSAGE'),
              true
            )
            .subscribe(() => {
              this.orderProcessedHandler(order_id);
            });
        }
      } else if (this.order.payment_method_id == 7) {
        const response: any = await this.paymentService.emsOnline(
          this.firm,
          this.firm_name,
          this.order.user.id,
          order_id,
          this.language,
          this.own_domain
        );

        if (response == 'ERROR') {
          this.deleteOrder();
        } else if (response == 'ALREADY_PAID') {
          this.dialogsService
            .openError(
              this.translate.instant('DIALOGS.ALREADY_PAID_TITLE'),
              this.translate.instant('DIALOGS.ALREADY_PAID_MESSAGE'),
              true
            )
            .subscribe(() => {
              this.orderProcessedHandler(order_id);
            });
        }
      } else if (this.order.payment_method_id == 10) {
        const response: any = await this.paymentService.mollie(
          this.firm,
          this.firm_name,
          this.order.user.id,
          order_id,
          this.language,
          this.own_domain
        );

        if (response == 'ERROR') {
          this.deleteOrder();
        } else if (response == 'ALREADY_PAID') {
          this.dialogsService
            .openError(
              this.translate.instant('DIALOGS.ALREADY_PAID_TITLE'),
              this.translate.instant('DIALOGS.ALREADY_PAID_MESSAGE'),
              true
            )
            .subscribe(() => {
              this.orderProcessedHandler(order_id);
            });
        }
      } else if (this.order.payment_method_id === 12) {
        const paymentType = 'adyen';

        const response: any = await this.paymentService.initiateOnlinePayment(
          paymentType,
          this.firm,
          this.order.user.id,
          order_id,
          this.language,
          this.own_domain
        );

        if (response === 'TOKEN_INVALID') {
          if (this.paymentCallCounter >= 5) {
            return this.dialogsService.openError(
              this.translate.instant('DIALOGS.ERROR'),
              this.translate.instant('PAYMENT_ERROR'),
              true
            );
          }

          this.paymentCallCounter += 1;
          return this.goToPayment(order_id);
        }

        if (response === 'ALREADY_PAID') {
          this.dialogsService.openError(
            this.translate.instant('DIALOGS.ALREADY_PAID_TITLE'),
            this.translate.instant('DIALOGS.ALREADY_PAID_MESSAGE'),
            true
          );
          this.orderProcessedHandler(order_id);
          return;
        }

        this.resetPaymentCounter();
      }
    } else {
      this.orderProcessedHandler(order_id);
    }

    this.loader.dismiss();
  }

  resetPaymentCounter() {
    this.paymentCallCounter = 0;
  }

  async deleteOrder() {
    if (
      this.order.payment_method_id == 2 ||
      this.order.payment_method_id == 3 ||
      this.order.payment_method_id == 4 ||
      this.order.payment_method_id == 5 ||
      this.order.payment_method_id == 6 ||
      this.order.payment_method_id == 7 ||
      this.order.payment_method_id == 10
    ) {
      this.orderService.delete(this.firm.id, this.order.id, this.order.user.id, this.language);
      this.order.id = undefined;
      await this.orderService.saveOrder(this.order);
    }
  }

  disableOrderButton() {
    return !this.enableOrderBtn || !this.hasItemsInCart() || this.firm.is_offline || !this.isInvoiceFormValid();
  }

  isInvoiceFormValid() {
    return (
      !this.order?.customer_is_professional || (this.order?.customer_is_professional && this.orderHasInvoiceAddress())
    );
  }

  hasItemsInCart() {
    return this.basketItems && this.basketItems.length > 0;
  }

  async checkBasketChange(error) {
    this.enable_change_basket = true;

    await error.errors.forEach(async (error) => {
      if (error.error == 'INVALID_PRODUCT_PRICE') {
        this.invalid_product = true;
        await this.editProductPrice(error.product_id, error.new_price);
      } else if (error.error == 'INVALID_INGREDIENT_PRICE') {
        this.invalid_ingredient = true;
        await this.editIngredientPrice(error.product_id, error.ingredient_id, error.old_price, error.new_price);
      } else if (error.error == 'INVALID_PRODUCT_OPTION_PRICE') {
        this.invalid_option = true;
        await this.editOptionPrice(
          error.product_id,
          error.product_group_id,
          error.product_option_id,
          error.old_price,
          error.new_price
        );
      } else if (error.error == 'NOT_IN_STOCK') {
        this.not_in_stock = true;
        await this.removeProductFromBasket(error.product_id);
      } else if (error.error == 'PRODUCT_NOT_FOUND' || error.error == 'PRODUCT_IS_SNOOZED') {
        this.product_not_available = true;
        this.basketItems.forEach((bi) => {
          if (bi.item.item.id == error.product_id) {
            error.product_name = bi.item.name;
          }
        });
        await this.removeProductFromBasket(error.product_id);
      } else if (error.error == 'INGREDIENT_IS_SNOOZED') {
        this.ingredient_not_available = true;
        await this.removeProductFromBasket(error.ingredient_id);
      } else if (error.error == 'PRODUCT_CATEGORY_NOT_AVAILABLE') {
        this.category_not_available = true;
        await this.removeCategoryFromBasket(error.product_category_id);
      } else {
        this.generalError = true;
        error.error = 'GENERAL_MESSAGE';
      }
    });
    this.errors = error.errors;

    await this.calculateTotalPrice();

    // trigger change detection
    this.basketItems = JSON.stringify(this.basketItems);
    this.basketItems = JSON.parse(this.basketItems);
  }

  removeCategoryFromBasket(category_id) {
    // Remove from basket_item
    this.basketItems.forEach(async (bi) => {
      if (bi.item.item.category.parent_category_id === category_id) {
        const index = await this.getIndex(this.basketItems, bi);
        if (index > -1) {
          this.basketItems.splice(index, 1);
        }
      }
    });

    // Remove from products
    this.order.products.forEach(async (product) => {
      if (product.parent_cat_id == category_id) {
        const index = await this.getIndex(this.order.products, product);
        if (index > -1) {
          this.order.products.splice(index, 1);
        }
      }
    });
  }

  async removeProductFromBasket(product_id) {
    // Remove from basket_item
    this.basketItems.forEach(async (bi) => {
      if (bi.item.id == product_id) {
        const index = await this.getIndex(this.basketItems, bi);
        if (index > -1) {
          this.basketItems.splice(index, 1);
        }
      }
    });

    // Remove from products
    this.order.products.forEach(async (product) => {
      if (product.id == product_id) {
        const index = await this.getIndex(this.order.products, product);
        if (index > -1) {
          this.order.products.splice(index, 1);
        }
      }
    });
  }

  editProductPrice(product_id, new_price) {
    this.basketItems.forEach(async (bi) => {
      if (bi.item.id == product_id) {
        bi.item.item.price = new_price;
        await this.calculateNewPrices(bi);
      }
    });
    this.order.products.forEach((product) => {
      if (product.id == product_id) {
        product.price = new_price;
      }
    });
  }

  editIngredientPrice(product_id, ingredient_id, old_price, new_price) {
    this.basketItems.forEach((bi) => {
      if (bi.item.id == product_id) {
        bi.item.ingredients.forEach(async (ingr) => {
          if (ingr.id == ingredient_id) {
            ingr.price = new_price;
            bi.item.item.price = bi.item.item.price - old_price;
            await this.calculateNewPrices(bi);
          }
        });
      }
    });
    this.order.products.forEach((product) => {
      if (product.id == product_id) {
        product.ingredients.forEach(async (ingredient) => {
          if (ingredient.id == ingredient_id) {
            ingredient.price = new_price;

            if (old_price == 0 && new_price > 0) {
              ingredient.type = 'paid';
            } else if (new_price == 0 && old_price > 0) {
              ingredient.type = 'free';
            }
          }
        });
      }
    });
  }

  editOptionPrice(product_id, product_group_id, product_option_id, old_price, new_price) {
    this.basketItems.forEach((bi) => {
      if (bi.item.id == product_id) {
        bi.item.product_options.forEach(async (option) => {
          if (option.id == product_group_id && option.selected_option.id == product_option_id) {
            option.selected_option.price = new_price;
            bi.item.item.price = bi.item.item.price - old_price;
            await this.calculateNewPrices(bi);
          }
        });
      }
    });
    this.order.products.forEach((product) => {
      if (product.id == product_id) {
        product.product_options.forEach((option) => {
          if (option.id == product_option_id) {
            option.price = new_price;
          }
        });
      }
    });
  }

  async calculateNewPrices(basketItem) {
    let ingredients_price = 0;
    let options_price = 0;

    // Check if free ingredients exist & set init ingredients list
    if (basketItem.item.ingredients) {
      await basketItem.item.ingredients.forEach((ingredient) => {
        ingredients_price = ingredients_price + ingredient.price;
      });
    }

    // Check option groups
    if (basketItem.item.product_options) {
      await basketItem.item.product_options.forEach((option_group) => {
        option_group.options.forEach((option) => {
          if (option_group.selected_option.selected == true) {
            options_price = options_price + option_group.selected_option.price;
          }
        });
      });
    }

    basketItem.item.basketItem_price = basketItem.item.item.price + ingredients_price + options_price;
    basketItem.item.total_price = basketItem.item.basketItem_price * Number(basketItem.item.amount);

    await this.calculateTotalPrice();
  }

  async calculateTotalPrice() {
    if (!this.firm.uses_vat) {
      this.sub_total = 0;
      this.basketItems.forEach((item) => {
        this.sub_total = this.sub_total + item.item.total_price;
      });

      this.total_price =
        this.sub_total -
        (this.sub_total / 100) * Number(this.order.discount_percentage) +
        Number(this.order.delivery_price);
    } else if (this.firm.uses_vat) {
      this.sub_total = 0;
      this.basketItems.forEach((basketItem) => {
        const item_price =
          basketItem.item.total_price -
          (basketItem.item.total_price / 100) * this.order.discount_percentage +
          ((basketItem.item.total_price - (basketItem.item.total_price / 100) * this.order.discount_percentage) / 100) *
            basketItem.item.item.vat_percentage;
        this.sub_total = Number(this.sub_total + item_price);
      });
    }
  }

  getIndex(searchArray, searchQuery) {
    return searchArray.indexOf(searchQuery);
  }

  displaySucceededMessage() {
    this.dialogsService
      .openError(
        this.translate.instant('DIALOGS.PAYMENT_SUCCEEDED_TITLE'),
        this.translate.instant('DIALOGS.PAYMENT_SUCCEEDED_MESSAGE'),
        true
      )
      .subscribe(() => {
        this.orderProcessedHandler(this.order.id);
      });
  }

  async checkCoupon() {
    const body = {
      coupon_code: this.coupon,
      total: this.total_price,
    };
    const response: any = await this.orderService.checkCoupon(this.firm.id, body, this.order.user.id, this.language);

    this.resetCouponErrors();

    if (response.code === 'ORDER_PRICE_TOO_LOW_FOR_COUPON') {
      // Set error
      this.couponTooLow = true;
      this.couponTooLowMsg = response.message;

      // Enable input
      this.enterCoupon = true;
    } else if (!response.valid) {
      // Set error
      if (response.used) this.couponAlreadyUsed = true;
      else this.couponInvalid = true;

      // Enable input
      this.enterCoupon = true;
    } else if (response.valid) {
      // Disable input
      this.enterCoupon = false;

      // Add coupon to the order
      this.addCouponToOrder(response);
    }
  }

  resetCouponErrors() {
    this.couponInvalid = false;
    this.couponAlreadyUsed = false;
    this.couponTooLow = false;
    this.couponTooLowMsg = undefined;
  }

  async addCouponToOrder(coupon) {
    this.order.coupon_code = this.coupon;
    this.order.coupon = {
      code: this.coupon,
      type: coupon.type,
    };

    if (coupon.max_usage) this.order.coupon.usageLeft = coupon.usage_left;

    if (coupon.type === 'amount' || coupon.type === 'percentage') {
      this.order.coupon.value = coupon.value;
    } else if (coupon.type === 'product') {
      this.order.coupon.free_product = coupon.product;
    } else if (coupon.type === 'category') {
      const response: any = await this.productService.getCategoryById(
        this.firm.id,
        this.language,
        coupon.category.id,
        this.firm.uses_deliverect
      );

      if (response.products && response.products.length > 0) {
        this.order.coupon.free_products = response.products;
      } else if (response.subcategories && response.subcategories.length > 0) {
        const array = [];
        response.subcategories.forEach((subcat) => {
          subcat.products.forEach((prod) => {
            prod.name = `${prod.name} (${subcat.name})`;
            array.push(prod);
          });
        });
        this.order.coupon.free_products = array;
      }

      this.setSelectedCouponProduct(this.order.coupon.free_products[0]);
    }

    this.saveCouponToOrder();
  }

  saveCouponToOrder() {
    if (this.order.coupon.type !== 'category') {
      this.order.coupon = JSON.stringify(this.order.coupon);
      this.order.coupon = JSON.parse(this.order.coupon);
    }
    this.orderService.saveOrder(this.order);
  }

  removeCouponFromOrder() {
    this.coupon = undefined;
    this.order.coupon_code = undefined;
    this.order.coupon = undefined;
    this.order.coupon_product_id = undefined;
    this.selected_coupon_product = undefined;
    this.couponInvalid = false;
    this.couponAlreadyUsed = false;
    this.enterCoupon = true;
    this.enableOrderBtn = true;
    this.orderService.saveOrder(this.order);
  }

  setSelectedCouponProduct(product) {
    this.selected_coupon_product = product;
    this.order.coupon_product_id = product.id;
  }

  totalPriceOutput(total_price) {
    this.total_price = total_price;
  }

  isFreeOutput(isFree) {
    this.isFree = isFree;
  }

  checkExtraOption(option) {
    option.selected ? this.removeExtraFromOrder(option) : this.addExtraToOrder(option);
    this.forceExtrasUpdate();
  }

  addExtraToOrder(option) {
    option.selected = true;
    !this.order.checkout_options ? (this.order.checkout_options = [option]) : this.order.checkout_options.push(option);
  }

  async removeExtraFromOrder(option) {
    option.selected = false;
    const index = this.order.checkout_options.indexOf(option);
    if (index > -1) this.order.checkout_options.splice(index, 1);
  }

  forceExtrasUpdate() {
    if (this.order.checkout_options) {
      this.extraOptions = JSON.parse(JSON.stringify(this.order.checkout_options));
    }
  }

  isLoyaltyActive() {
    return (
      this.firm &&
      this.loyaltyService.isActive(this.firm.use_loyalty, this.firm.loyalty) &&
      this.loyaltyService.canBeAddedToOrder(this.order.add_loyalty_to_order)
    );
  }

  setLoyaltyProducts() {
    this.loyaltyProducts = this.loyaltyService.getProductList(
      this.firm.loyalty.loyalty_type,
      this.firm.loyalty.free_product,
      this.firm,
      this.language
    );
  }

  loyaltyProductChanged(product) {
    this.order.loyalty_product_id = product.id;
    this.order.loyalty_product_name = product.name;
  }

  orderProcessedHandler(orderId) {
    this.tagService.addEvent('eo_web_order', this.total_price);
    this.routeService.navigateToThankPage(this.own_domain, this.firm, this.language, orderId);
  }

  invoicingIsPossible() {
    return this.firm?.has_module_invoicing && !this.order.isGuest;
  }

  userHasBusinessDetails(user) {
    return this.customerService.userHasBusinessDetails(user);
  }

  orderHasInvoiceAddress() {
    return (
      this.order?.address_billing &&
      this.order?.zipcode_billing &&
      this.order?.locality_billing &&
      this.order?.country_id_billing
    );
  }

  invoiceSegmentChanged(checked) {
    if (checked) {
      this.order.customer_is_professional = true;
      if (!this.addressList) {
        this.setAddressList();
      }
    } else {
      this.order.customer_is_professional = false;
      this.removeInvoiceAddressFromOrder();
      this.selectedInvoiceAddress = null;
    }
  }

  async setAddressList() {
    this.addressList = await this.getAddresses();
  }

  async presentSelectInvoiceAddressModal() {
    const header = this.translate.instant('ADD_ADDRESS');
    const subHeader = this.translate.instant('FILL_IN_ADDRESS');
    const buttonLabel = this.translate.instant('CONTINUE');

    const dialog = this.dialogsService.openInvoiceAddressModal(
      header,
      subHeader,
      buttonLabel,
      this.addressList,
      this.selectedInvoiceAddress?.id
    );
    dialog.subscribe(({ data }) => {
      if (data.saved) {
        this.setInvoiceAddressOnOrder(data.address);
        this.selectedInvoiceAddress = data.address;
      }
    });
  }

  async getAddresses(): Promise<Array<Address>> {
    return this.customerService.getAllCustomerAddresses(this.user.id, this.user.uuid, this.firm.id, this.language);
  }

  setInvoiceAddressOnOrder(address) {
    const streetNr = address.bus ? `${address.house_number} ${address.bus}` : address.house_number;
    this.order.address_billing = `${address.street} ${streetNr}`;
    this.order.zipcode_billing = address.zip_code;
    this.order.locality_billing = address.locality;
    this.order.country_id_billing = address.country?.id;
  }

  removeInvoiceAddressFromOrder() {
    this.order.address_billing = null;
    this.order.zipcode_billing = null;
    this.order.locality_billing = null;
    this.order.country_id_billing = null;
  }

  invoicingAddressHasErrors() {
    return this.selectedInvoiceAddress && !this.isInvoiceFormValid();
  }
}
