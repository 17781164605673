<a class="menu-card"
  [ngClass]="{ 'gold-container': isGold }"
  (click)="clickedHandler()">

  <div class="menu-card--image" *ngIf="image">
    <img [src]="image" alt="">
  </div>

  <eo-extra-points *ngIf="points"
    [points]="points"
    [position]="'top-right'">
  </eo-extra-points>

  <div class="menu-card--footer">
    {{ title }}
  </div>
</a>
